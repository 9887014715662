import { Controller } from '../../controller'
import api from '../../store/requests/api'
import {makeObservable, observable} from 'mobx'
import {message} from 'antd';

class CardController extends Controller {

    list = []
    now = 1

    element = {
        id: 0,
        name: '',
        description: '',
        file: null,
        image: null,
        ru: 0,
        en: 0
    }

    deleteImage = false

    constructor(props) {
        super(props)
        makeObservable(this, {
            list: observable,
            now: observable,
            element: observable,
        })
    }

    deleteImageCard(cardId) {
        this.deleteImage = true
        this.element.image = null
        this.send(cardId)
    }

    upload(info) {
        console.log(info)
        this.element.file = info
        return false
    }

    getList() {
        console.log(`/card_day?limit=${this.limit}&offset=${this.offset}`)
        api.get(`/card_day?limit=${this.limit}&offset=${this.offset}`)
            .then(result => {
                console.log(result)
                this.list = result.data.items
                this.setCount(result.data.count)
            })
            .catch(error => console.log(error))
    }

    getOne(cardId) {
        api.get(`/card_day/${cardId}`)
            .then(result => {
                console.log(result)
                this.element = result.data
            })
            .catch(error => console.log(error))
    }

    delete(cardId) {
        api.delete(`/card_day/${cardId}`)
            .then(result => {
                this.getList()
            })
            .catch(error => console.log(error))
    }

    setNow(page) {
        let page_now = page - 1 >= 0 ? page - 1 : 0
        this.offset = page > 1 ? this.limit * page_now : 0;
        this.now = page
        this.getList()
    }

    send(cardId = 0) {
        const data = new FormData()
        data.append('name', this.element.name)
        data.append('description', this.element.description)
        data.append('card', this.element.file)
        data.append('ru', this.element.ru)
        data.append('en', this.element.en)

        if(this.deleteImage === true) data.append('delete', 'true')

        api.post(`/card_day/${cardId}`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(result => {
                if(cardId > 0) {
                    this.getOne(cardId)
                    message.success('Элемент успешно сохранен');
                } else {
                    window.location.href = '/cards'
                    return false
                }
                this.deleteImage = false
            })
            .catch(error => console.log(error))
    }

    sendForm(e, cardId = 0) {
        e.preventDefault()

        this.send(cardId)
    }

    onChange(name, value) {
        this.element[name] = value
    }

}

export default new CardController()